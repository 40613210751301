export const NavItems = [
    {
        title: "WORK",
        link: "/work",
        externalLink: false,
    }, 
    {
        title: "RESUME",
        link: "https://drive.google.com/file/d/1u1YIDCLt0qkCD3xAqhAmhHeRlTMty6oY/view?usp=sharing",
        externalLink: true
    }
]
